import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CSimpleAccordionList,
  CBtnList,
  AssetImage,
} from '../../../../components/_index';
import shopGet from '../../../../utils/shop-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          title: {
            en: '貸切会食',
            ja: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/oiwai/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/oiwai/kv__sp.png',
              },
            },
          ],
        }}
        exClass="c_jumbotron_keikaen"
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: '中国料理「桂花苑」',
                path: '/restaurants/keikaen/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[1]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ sub: '', main: '貸切会食' }}
          />
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/oiwai/img_oiwai.png',
                  alt: '',
                },
                title: (
                  <>
                    大切な方々との想い出を創る
                    <br />
                    貸切での会食プラン
                  </>
                ),
                text: (
                  <>
                    <p>
                    リゾートホテルの最上階を貸し切って、気兼ねない少⼈数の会⾷から、⼤切なお客様を招いてのおもてなし、ビジネスシーンでの会⾷などお得で豊富なプランを数々ご⽤意。<br />お客様の希望を叶える貸切会⾷をお⼿伝いいたします。
                    </p>
                  </>
                ),
                btn: {
                  label: 'MAILでのお問合せ',
                  link: {
                    href: 'https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              },
            ]}
          />
          <p className="news_title">プラン</p>
          <CSimpleAccordionList
            data={[
              {
                title: <>桂花名菜貸切ランチプラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      15名～40名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 4,500円
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      11:30〜17:00までの1時間30分
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <ul className="c_circleList">
                        <li>点⼼三種盛り合わせ</li>
                        <li>スープ</li>
                        <li>五⽬⼊りあんかけ焼きそば</li>
                        <li>デザート</li>
                        <li>コーヒー</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          ⽉により内容は変更いたします。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。　　　　　　　　　　　　　
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <>飲茶ランチコース貸切パーティープラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan02.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      15名～40名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 5,200円
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      11:30〜17:00までの1時間30分
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <ul className="c_circleList">
                        <li>前菜盛り合わせ</li>
                        <li>蒸し点⼼3種盛り合わせ</li>
                        <li>⽜⾁とピーマンの細切り炒め</li>
                        <li>五⽬⼊りチャーハン</li>
                        <li>デザートブッフェ<br />（杏仁⾖腐、タピオカ⼊りココナッツミルク、愛⽟ゼリー、胡⿇団⼦、桃饅頭、マーラーカオなど）</li>
                        <li>コーヒー</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          ⽉により内容は変更いたします。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <>⼩籠包⾷べ放題貸切ランチプラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan03.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      15名～40名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 6,000円
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      11:30〜17:00までの1時間30分
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <ul className="c_circleList">
                        <li>前菜盛り合わせ</li>
                        <li>⼩籠包⾷べ放題</li>
                        <li>海⽼のチリソース煮</li>
                        <li>季節野菜のあんかけ焼きそば</li>
                        <li>デザート</li>
                        <li>コーヒー</li>
                      </ul>
                      <ul className="c_noteList">
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。　　　　　　　　　　　　　
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <>チャイニーズブッフェ貸切ランチプラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan04.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      15名～30名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 6,800円
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      11:30〜17:00までの1時間30分
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <ul className="c_circleList">
                        <li>冷菜各種、海⽼⼊り蒸し餃⼦</li>
                        <li>焼売、春巻、⾁まん</li>
                        <li>⽜⾁のオイスターソース炒め、海⽼のチリソース煮</li>
                        <li>⿇婆⾖腐、油淋鶏、あんかけ焼きそば、炒飯</li>
                        <li>杏仁⾖腐、胡⿇団⼦、タピオカ⼊りココナッツミルク</li>
                        <li>コーヒー</li>
                      </ul>
                      <ul className="c_noteList">
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。　　　　　　　　　　　　　
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <>卓盛パーティープラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan05.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      10名～40名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 5,200円～
                      <ul className="c_noteList">
                        <li>
                        貸切料として別途20,000円を頂戴いたします。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      ランチタイム：11:30〜17:00までの2時間
                      <br />
                      ディナータイム：17：30〜21：00までの2時間
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <br />
                      メニュー内容はお問合せください。
                      <ul className="c_noteList">
                        <li>
                        卓盛プランは⼤⽫料理提供となります。皆様で取り分けてお召し上がりください。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。　　　　　　　　　　　　　
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <>中華正餐パーティープラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan06.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      10名～40名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 6,600円～
                      <br />
                      ※ 貸切料として別途20,000円を頂戴いたします。
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      ランチタイム：11:30〜17:00までの2時間
                      <br />
                      ディナータイム：17：30〜21：00までの2時間
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <ul className="c_circleList">
                        <li>前菜盛り合わせ</li>
                        <li>宮城県 気仙沼名物『ふかひれ』⼊りスープ </li>
                        <li>海⽼のチリソース煮とマヨネーズ和え</li>
                        <li>⽜⾁のオイスターソース炒め</li>
                        <li>蟹⾁とレタス⼊り炒飯</li>
                        <li>デザート</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          ⽉により内容は変更いたします。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      その他、8,800円 / 11,000円 のメニューも承ります。
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。　　　　　　　　　　　　　
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <>ブッフェパーティープラン</>,
                content: (
                  <>
                  <div className='instagramWrapper'>
                    <figure className="imgWrapper">
                      <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_plan07.png" alt="" />
                    </figure>
                  </div>
                  <div className='instagramWrapperList'>
                    <p className="u_mb30">
                      人数
                      <br />
                      15名～40名
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      1名様 7,000円～
                      <ul className="c_noteList">
                        <li>
                        貸切料として別途20,000円を頂戴いたします。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      ご利⽤時間
                      <br />
                      ランチタイム：11:30〜17:00までの2時間
                      <br />
                      ディナータイム：17：30〜21：00までの2時間
                    </p>
                    <p className="u_mb30">
                      メニュー例
                      <ul className="c_circleList">
                        <li>胡⽠の冷菜、窯焼きチャーシューなど前菜各種</li>
                        <li>海⽼⼊り蒸し餃⼦焼売、春巻、海⽼のチリソース煮</li>
                        <li>⿇婆⾖腐、油淋鶏、あんかけ焼きそば、炒飯</li>
                        <li>杏仁⾖腐、胡⿇団⼦、タピオカ⼊りココナッツミルクなど</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          ⽉により内容は変更いたします。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      その他、8,800円 / 11,000円 のメニューも承ります。
                      <br />
                      ページ下部の<a href="/restaurants/keikaen/oiwai#drink-plan">ドリンクフリーフロープラン</a>も別途お承りいたします。　　　　　　　　　　　　　
                    </p>
                  </div>
                  </>
                ),
              },
              {
                title: <><div id="drink-plan">ドリンクフリーフロープラン</div></>,
                content: (
                  <>
                  
                    <div className='instagramWrapper'>
                      <figure className="imgWrapper">
                        <AssetImage src="/assets/images/restaurants/keikaen/oiwai/img_drinkplan.png" alt="" />
                      </figure>
                    </div>
                    <div >
                    <div id="drink-plan1">
                      <div id="ab">
                        <p className="u_mb30">
                          A 1,800円
                          <br />
                          ウーロン茶
                          <br />
                          オレンジジュース
                          <br />
                          グレープフルーツジュース　　　　　　　　　　
                          <br />
                          コカ‧コーラ
                          <br />
                          ジンジャーエール
                        </p>
                      </div>
                      <div id="ac">
                        <p className="u_mb30">
                          B 2,500円
                          <br />
                          Aのお飲み物全種
                          <br />
                          瓶ビール
                          <br />
                          ⾚⽩ワイン
                          <br />
                          ウィスキー＆ソーダ　　　　　　　　　　　　
                        </p>
                      </div>
                      </div>
                      <div id="drink-plan1">
                        <div id="ad" > 
                          <p className="u_mb30">
                            C 3,300円
                            <br />
                            A‧Bのお飲み物全種
                            <br />
                            紹興酒
                            <br />
                            焼酎（⻨‧芋）
                            <br />
                            ⽇本酒 
                            <br />
                            カクテル（カシス‧ジン）
                            <br />
                            ノンアルコールビール
                            <br />
                            ノンアルコールカクテル
                            <br />
                            ノンアルコールワイン
                            <br />
                            ノンアルコールハイボール　　　　　　　　
                          </p>
                        </div>
                        <div id="ae" > 
                          <p className="u_mb30">
                            D 3,980円
                            <br />
                            A‧B‧Cのお飲み物全種
                            <br />
                            乾杯酒
                            <br />
                            (スパークリングワインor
                            <br />
                            ノンアルコールスパークリング
                            <br />
                            ワイン) 
                            <br />
                            ⽣ビール
                            <br />
                            伊達ウィスキー＆ソーダ
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                  
                ),
              },
            ]}
            exClass="u_mbExLarge"
          />
           <CBtnList
              exClass="u_mb60"
              data={[
                {
                  label: 'プランについて',
                  link: {
                    href: '/assets/files/pdf/pdf_katura_plan.pdf',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              ]}
            />
          <ul className="c_noteList">
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              ザ
              クラブ・ロイヤルパークホテルズ会員割引・ポイント付与対象となります。個室料やその他付帯商品は対象外となります。
            </li>
          </ul>
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ main: 'GUIDE', sub: '営業時間｜ご利用案内' }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
